import React from "react"
import "./App.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/js/bootstrap.min.js';
import { StaffLogin, Dashboard, DashboardLayout, Layout, Homedesign, TextBlock, FullWidthBanners, Carousels } from "./Components"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from "./Utils/ProtectedRoute"
import Logout from "./Utils/Logout";
import {
  NotFound, ProfilePage, Users, UsersAdd, AuditionsList, Verified, VerifiedList, VerifiedInfo, HCCOrder, RazorpayList, RequirementsList,
  AuditionRequirement,
  AudiInterests
} from "./Pages"

import { Calendar } from "antd";
// import ChartTable from "./Components/DashboardLayout/Chart.js"
import ChartTable from "./Components/DashboardLayout/Chart";
import { AuditionInfo } from "./Pages/AuditionInfo";

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<StaffLogin />} />
        <Route path="" element={<ProtectedRoute />} >
          <Route path="/logout" element={<Logout />} />
          <Route path="/:url" element={<Layout />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/dashboard" element={<ChartTable />} />
          {/* OpotoAdmin */}
          {/* <Route path="/dashboard/orders" element={<Orders />} />
          <Route path="/dashboard/store_management" element={<StoreManagement />} />
          <Route path="/dashboard/:storeId/store_management" element={<StoreManagement />} />
          <Route path="/dashboard/admin/profile" element={<AdminProfile />} />
          <Route path="/dashboard/add_store" element={<AddStore />} />
          <Route path="/dashboard/masters/subcategories" element={<Subcategories />} /> */}
          {/* <Route path="/dashboard/users/add" element={<UsersAdd />} /> */}
          <Route path="/dashboard/users" element={<Users />} />
          <Route path="/dashboard/users/:id/edit" element={<ProfilePage />} />
          <Route path="/dashboard/masters/Auditions" element={<AuditionsList />} />
          <Route path="/dashboard/masters/verified" element={<VerifiedList />} />
          <Route path="/dashboard/masters/Auditions/:id/edit" element={<AuditionInfo />} />
          <Route path="/dashboard/masters/verified/:id/edit" element={<VerifiedInfo />} />
          <Route path="/dashboard/masters/orders" element={<HCCOrder />} />
          <Route path="/dashboard/masters/razor-pays" element={<RazorpayList />} />
          <Route path="/dashboard/masters/Audition-requirements" element={<RequirementsList />} />
          <Route path="/dashboard/masters/Audition-requirements/:id/edit" element={<AuditionRequirement />} />
          <Route path="/dashboard/masters/Audition-interests" element={<AudiInterests />} />
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
