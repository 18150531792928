import React, { useState, useEffect } from "react";
import { NotFound } from "../../Pages";
import axiosConfig from "../../Service/axiosConfig"
import Select from 'react-select';
import { IoMdDownload } from "react-icons/io";
import { FaFileCsv, FaFileExcel, FaFilePdf, FaFileWord } from "react-icons/fa";
const FormParser = ({ modelObject, formData, formSubmit, error, buttonTitle, submitInProgress, id, readonly, button }) => {
    const [Data, SetData] = useState({})
    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(false);
    const [dynamicOptions, SetDynamicOptions] = useState({})
    const [DataFilter, SetDataFilter] = useState([])
    const [Option, setOption] = useState({});
    const [Error, SetError] = useState("")
    const [FieldErrors, setFieldErrors] = useState({});
    const [nextPageUrl, setNextPageUrl] = useState('');
    const [search, setSearch] = useState('');
    const [dropdownValues, setDropDownValues] = useState({})
    const GetData = () => {
        let data = []
        field_objects.forEach((field_obj, ind) => {
            if (field_obj.fieldType === "dynamicdropdown") {
                console.log(field_obj.fieldName, "list")
                const url = `${field_obj.options_url}&search_query=${search}`;
                axiosConfig.get(url).then(response => {
                    setNextPageUrl(prevOptions => ({
                        ...prevOptions,
                        [field_obj.fieldName]: response.data.next
                    }));
                    setDropDownValues(prevOptions => ({
                        ...prevOptions,
                        [field_obj.fieldName]: response.data.results
                    }));
                }).catch((error) => {
                    console.log(error);
                });
            }
        });
    };

    const GetMultiData = () => {
        let data = []
        field_objects.forEach((field_obj, ind) => {
            if (field_obj.fieldType === "dynamicMultiSelectdropdown") {
                if (field_obj.options_url == '/masters/languages/?page_size=1000') {
                    console.log("entered languages")
                    if (localStorage.getItem('languages')) {
                        let languages = JSON.parse(localStorage.getItem('languages'))
                        console.log("getting languages")
                        dynamicOptions[field_obj.fieldName] = languages
                    }
                    else {
                        console.log("notlocal languages")
                        axiosConfig.get(field_obj.options_url).then(response => {
                            SetDynamicOptions({ ...dynamicOptions, [field_obj.fieldName]: response.data.results })
                            localStorage.setItem('languages', JSON.stringify(response.data.results))
                            dynamicOptions[field_obj.fieldName] = response.data.results
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                }
                else {
                    axiosConfig.get(field_obj.options_url).then(response => {
                        SetDynamicOptions({ ...dynamicOptions, [field_obj.fieldName]: response.data.results })
                        dynamicOptions[field_obj.fieldName] = response.data.results
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            }
        })
    }
    // const GetDynamicData = (id) => {
    //     console.log(id)
    //     let data=[]
    //     field_objects.forEach((field_obj, ind) => {
    //         if (field_obj.fieldType === "dynamicSelectdropdown") {
    //             axiosConfig.get(`${field_obj.options_url}${id}/`).then(response => {
    //                 SetSelectedOptions({ ...SelectedOptions, [field_obj.fieldName]: response.data })
    //                 SelectedOptions[field_obj.fieldName] = response.data
    //                 console.log(response.data)
    //             }).catch((error) => {
    //                 console.log(error)
    //             })
    //         }
    //     })
    // }
    const [SelectDynamicOptions, SetSelectDynamicOptions] = useState([])
    const SelectOptions = (id) => {
        field_objects.forEach(item => {
            if (item.fieldType === "dynamicSelectdropdown") {
                console.log(id, `${item.options_url}${id}`)
                let url = `${item.options_url}${id}`
                axiosConfig.get(url).then(res => {
                    console.log(res.data.results)
                    SetSelectDynamicOptions(res.data.results)
                }).catch(error => {
                    console.log(error)
                })
            }
        })
    }
    const editDataRender = async (formData) => {
        field_objects.forEach(async (field_obj, idx) => {
            if (formData) {
                Data[field_obj.fieldName] = formData[field_obj.fieldName]
                SetData(formData)
                if (field_obj.fieldType === "dynamicSelectdropdown") {
                    if (formData[field_obj.renderField]) {
                        let url = `${field_obj.options_url}${formData[field_obj.renderField]}`
                        console.log(url)
                        await axiosConfig.get(url).then(res => {
                            SetSelectDynamicOptions(res.data.results)
                            const find = res.data.find(find => find.id === formData[field_obj.fieldName])
                            setOption({ value: find.id, label: find[field_obj.displayField] })
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                }
                if (field_obj.fieldType === "dynamicMultiSelectdropdown" && Object.keys(dynamicOptions).length > 0) {
                    let data = []
                    formData && formData[field_obj.fieldName] && formData[field_obj.fieldName].forEach(value => {
                        const findData = dynamicOptions[field_obj.fieldName].find(find => find.id === value)
                        data.push(findData)
                    })
                    setSelectedOptions(data)
                }
            }
        })
        setTimeout(function () {
            setLoading(false);
        }.bind(this), 500)
    }
    const [selectedOptions, setSelectedOptions] = useState([])
    useEffect(() => {
        GetData();
        GetMultiData()
        editDataRender(formData)
    }, [formData])
    useEffect(() => {
        if (search) {
            GetData()
        }
    }, [search])
    console.log(dynamicOptions)
    const [FilterData, SetFilterdata] = useState([])
    const [Name, SetName] = useState("")
    const HandleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        SetData({ ...Data, [name]: value })
        validateField(name, value)
        SetName(value)
    }
    const handleSelectChange = (selectedOption, name) => {
        console.log(selectedOption.value, name)
        SetData({ ...Data, [name]: selectedOption.value });
        SelectOptions(selectedOption.value)
    };
    const handleMultiSelectChange = (selectedOption, valu, name) => {
        let data = []
        selectedOption.forEach(res => {
            data.push(res.value)
        })
        SetData({ ...Data, [name]: data });
    };
    const HandleFiles = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        const name = e.target.name;
        const value = file;
        SetData({ ...Data, [name]: value })
    }
    const HandleImages = (e) => {
        e.preventDefault();
        const file = e.target.files[0];

        // Check if file is selected
        if (file) {
            const name = e.target.name;
            const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"];
            const maxSizeMB = 10;

            // Check file type
            if (!allowedTypes.includes(file.type)) {
                alert("Only JPEG, JPG, PNG, and GIF files are allowed.");
                // Optionally clear the input
                e.target.value = null;
                return;
            }

            // Check file size
            if (file.size > maxSizeMB * 1024 * 1024) {
                alert("File size exceeds 10MB limit.");
                // Optionally clear the input
                e.target.value = null;
                return;
            }

            // If both checks pass, update state
            const value = file;
            SetData({ ...Data, [name]: value });
        }
    };

    const HandleMultiPulFiles = (e) => {
        e.preventDefault()
        const file = e.target.files;
        const name = e.target.name;
        const value = file;
        SetData({ ...Data, [name]: value })
    }
    const OnSubmit = (e) => {
        e.preventDefault()
        formSubmit(Data)

    }
    console.log(Data)
    const validateField = (name, value) => {
        const regex = {
            country_name: /^[a-zA-Z]+$/,
            state_name: /^[a-zA-Z]+$/,
            city_name: /^[a-zA-Z]+$/,
            pincode: /^[0-9]+$/,
            email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            cpt_code: /^[0-9]+$/,
        };
        const isInputValid = regex[name] ? regex[name].test(value) : true;
        const fields = field_objects.find(fields => fields.fieldName === name)
        console.log(isInputValid)
        SetError(isInputValid ? '' : `Enter a valid ${fields.label}`)

    }
    let field_objects = []
    modelObject.orderBy.forEach((order_obj, idx) => {
        modelObject.fields.forEach((field_obj, index) => {
            if (field_obj.fieldName === order_obj) {
                let flagAddClass = true
                modelObject.layoutSpecificFieldClasses.forEach((classObj, iex) => {
                    if (classObj[field_obj.fieldName]) {
                        field_obj['groupClass'] = classObj[field_obj.fieldName]
                        flagAddClass = false
                    }
                })
                if (modelObject.layoutClass && flagAddClass) {
                    field_obj['groupClass'] = modelObject.layoutClass
                }
                field_objects.push(field_obj)
            }
        })
    })
    const handleOptionClick = (option) => {
        SetName(option)
        SetFilterdata([])
    };
    function getFileIcon(documentUrl) {
        if (documentUrl.type) {
            if (documentUrl.type === 'application/pdf') {
                return <FaFilePdf color='red' size={20} />;
            } else if (documentUrl.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                documentUrl.type === 'application/vnd.ms-excel') {
                return <FaFileExcel size={20} />;
            } else if (documentUrl.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                documentUrl === 'application/msword') {
                return <FaFileWord size={20} />;
            } else if (documentUrl.type === 'text/csv') {
                return <FaFileCsv size={20} />;
            }
            else if (documentUrl.type === "application/x-msdownload") {
                return <IoMdDownload size={20} />
            }
            else {
                // Return a default icon or null if no matching file type
                return (
                    <img
                        src={URL.createObjectURL(documentUrl)}
                        width="30"
                        className="form-edit-img"
                    />
                )
            }
        }
        else {
            if (documentUrl.endsWith(".pdf")) {
                return <FaFilePdf color='red' size={20} />;
            } else if (documentUrl.endsWith(".xlsx") || documentUrl.endsWith(".xls")) {
                return <FaFileExcel size={20} />;
            } else if (documentUrl.endsWith(".docx") || documentUrl.endsWith(".doc")) {
                return <FaFileWord size={20} />;
            } else if (documentUrl.endsWith(".csv")) {
                return <FaFileCsv size={20} />;
            }
            else if (documentUrl.endsWith(".exe")) {
                return <IoMdDownload size={20} />;
            }
            else {
                return (
                    <img
                        src={documentUrl}
                        width="30"
                        className="form-edit-img"
                    />
                )
            }
        }
    }
    const handleScroll = async (fieldName, fieldType) => {
        if (!loading && nextPageUrl) {
            setLoading1(true);
            try {
                const product = await axiosConfig.get(
                    nextPageUrl[fieldName]
                );
                const product_data = product.data.results;

                setNextPageUrl(prevOptions => ({
                    ...prevOptions,
                    [fieldName]: product.data.next
                }));
                SetDynamicOptions(prevOptions => ({
                    ...prevOptions,
                    [fieldName]: [
                        ...(prevOptions[fieldName] || []), // Preserve existing data
                        ...product_data // Append new data
                    ]
                }));
            } catch (error) {
                console.error(`Error fetching data for ${fieldName}:`, error);
            }
            try {

            } finally {
                setLoading1(false);
            }
        }
    };
    const handleSearch = (value) => {
        console.log(value)
        setSearch(value)
    }
    return (
        <>
            <div className="container-form">
                {loading ? (
                    <div className="loader-container">
                        <img src={require('./images/loader.gif')} alt="Loading..." />
                    </div>
                ) : (
                    <form onSubmit={OnSubmit}>
                        {/* <div className="Loginerror">
                            <p>{error}</p>
                        </div> */}
                        {Error && <div className="Loginerror">
                            <p>{Error}</p>
                        </div>}
                        <div className="row">
                            {field_objects &&
                                field_objects.map((fieldItem, i) => {
                                    const isRequired = fieldItem.required ? '*' : '';
                                    const hasError = Error && Error.includes(`Enter a valid ${fieldItem.label}`);
                                    const isRequiredAndEmpty = fieldItem.required && !Data[fieldItem.fieldName];
                                    return (
                                        <div key={fieldItem.fieldName} className={fieldItem.groupClass}>
                                            {fieldItem.fieldType === "string" &&
                                                <div className="form-group">
                                                    <label htmlFor="validationCustom01" >{fieldItem.label}:</label>
                                                    <input type="text" className="form-control" id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readonly} />
                                                    {/* <div className="Loginerror">
                                                    <p>{error}</p>
                                                </div> */}
                                                </div>}
                                            {fieldItem.fieldType === "email" &&
                                                <div className="form-group">
                                                    <label htmlFor="validationCustom01" >{fieldItem.label}:</label>
                                                    <input type="email" className="form-control" id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readonly} />
                                                    {/* <div className="Loginerror">
                                                    <p>{error}</p>
                                                </div> */}
                                                </div>}
                                            {fieldItem.fieldType === "url" &&
                                                <div className="form-group">
                                                    <label htmlFor="validationCustom01" >{fieldItem.label}:</label>
                                                    <input type="url" className="form-control" id="validationCustom01" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readonly} />
                                                    {/* <div className="Loginerror">
                                                    <p>{error}</p>
                                                </div> */}
                                                </div>}
                                            {fieldItem.fieldType === "boolean" &&
                                                <div className="form-group form-check">
                                                    <div className="form-check-label d-flex align-items-center gap-2">
                                                        {console.log(fieldItem, "checkbox", Data)}
                                                        <input type="checkbox" className="form-check-input" name={fieldItem.fieldName} value="true" checked={Data[fieldItem.fieldName]} onChange={HandleChange} required={fieldItem.required} disabled={readonly} style={{ height: "25px", width: "25px" }} />
                                                        <label htmlFor="validationCustom01" style={{ marginBottom: "0" }}>{fieldItem.label}</label>
                                                    </div>
                                                </div>
                                            }
                                            {fieldItem.fieldType === "file" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}:</label>
                                                    <input type="file" className="form-control" placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleFiles} required={fieldItem.required} disabled={readonly} />
                                                </div>
                                            }
                                            {fieldItem.fieldType === "fileimage" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}:</label>
                                                    {Data && Data[fieldItem.fieldName] && (
                                                        <a href={
                                                            typeof Data[fieldItem.fieldName] === 'string'
                                                                ? Data[fieldItem.fieldName]  // Use the string as-is if it's a URL or file path
                                                                : URL.createObjectURL(Data[fieldItem.fieldName])  // Create URL for File object
                                                        } target="_blank"
                                                        >
                                                            {getFileIcon(Data[fieldItem.fieldName])}

                                                        </a>
                                                    )}

                                                    <input type="file" className="form-control" placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleImages} required={fieldItem.required} disabled={readonly} />
                                                </div>
                                            }
                                            {fieldItem.fieldType === "date" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}:</label>
                                                    <input type="date" className="form-control" placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readonly} />
                                                </div>
                                            }
                                            {fieldItem.fieldType === "time" &&
                                                <div className="form-group">

                                                    <label >{fieldItem.label}:</label>
                                                    <input type="time" className="form-control" placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readonly} />
                                                </div>
                                            }
                                            {fieldItem.fieldType === "integer" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}:</label>
                                                    <input type="number" className="form-control" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readonly} />
                                                </div>
                                            }
                                            {fieldItem.fieldType === "mobile" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}:</label>
                                                    <input type="tel" className="form-control" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} minLength={fieldItem.min_length ? fieldItem.min_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readonly} onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} />
                                                </div>
                                            }
                                            {fieldItem.fieldType === "password" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}:</label>
                                                    <input type="password" className="form-control" placeholder={fieldItem.label} name={fieldItem.fieldName} maxLength={fieldItem.max_length ? fieldItem.max_length : null} onChange={HandleChange} defaultValue={Data[fieldItem.fieldName]} required={fieldItem.required} disabled={readonly} />
                                                </div>
                                            }
                                            {fieldItem.fieldType === "radio" &&
                                                <div className="form-group">
                                                    <label >{fieldItem.label}:</label>
                                                    <br />

                                                    {fieldItem.options &&
                                                        fieldItem.options.map((optionItem, index) => {
                                                            return (
                                                                <div className="form-check-inline" key={index}>
                                                                    <label className="form-check-label">
                                                                        <input type="radio" className="form-check-input" name={fieldItem.fieldName} value={optionItem} onChange={HandleChange} required={fieldItem.required} disabled={readonly} />{optionItem}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            }
                                            {fieldItem.fieldType === "textarea" &&
                                                <div className="form-group">
                                                    <label>{fieldItem.label}:</label>
                                                    <textarea className="form-control" rows="2" placeholder={fieldItem.label} name={fieldItem.fieldName} onChange={HandleChange} required={fieldItem.required} disabled={readonly} defaultValue={Data[fieldItem.fieldName]}></textarea>
                                                </div>
                                            }

                                            {
                                                fieldItem.fieldType === "dropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}:</label>
                                                    <select className="form-select" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange} required={fieldItem.required} disabled={readonly} >
                                                        <option value=""></option>
                                                        {
                                                            fieldItem.options.map((optionItem, i) => {
                                                                return (
                                                                    <option value={optionItem} key={i}>{optionItem}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            }
                                            {
                                                fieldItem.fieldType === "dynamicdropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}:</label>
                                                    {/* <select className="form-select" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange} required={fieldItem.required}>
                                                        <option value="">SELECT {fieldItem.label}</option>
                                                        {
                                                            dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((optionItem, i) => {
                                                                return (
                                                                    <option value={optionItem.id} key={i} selected={optionItem.id === Data[fieldItem.fieldName]}>{optionItem[fieldItem.displayField]}</option>
                                                                )
                                                            })
                                                        }

                                                    </select>  */}
                                                    {console.log(Data[fieldItem.displayField], dropdownValues)}
                                                    <Select options={dropdownValues[fieldItem.fieldName] && dropdownValues[fieldItem.fieldName].map((res) => (
                                                        { "value": res.id, "label": res[fieldItem.displayField] }
                                                    ))} onMenuScrollToBottom={() => handleScroll(fieldItem.fieldName)}
                                                        isLoading={loading1}
                                                        onInputChange={handleSearch} placeholder={fieldItem.label} value={dropdownValues[fieldItem.fieldName] && dropdownValues[fieldItem.fieldName].map(res => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(res => res.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} required={fieldItem.required} isDisabled={readonly} />
                                                </div>
                                            }

                                            {fieldItem.fieldType === "dynamicSelectdropdown" && <div key={i} className="form-group">
                                                <label className="form-label">{fieldItem.label} :</label>
                                                {console.log(Data[fieldItem.fieldName])}
                                                <div className="input-box">
                                                    <Select className={FieldErrors[fieldItem.fieldName] ? "invalid-error" : ""} options={SelectDynamicOptions && SelectDynamicOptions.map((res) => (
                                                        { "value": res.id, "label": res[fieldItem.displayField] }
                                                    ))} placeholder={fieldItem.label} value={SelectDynamicOptions && SelectDynamicOptions.map((res) => ({ "value": res.id, "label": res[fieldItem.displayField] })).find(find => find.value === Data[fieldItem.fieldName])} onChange={(selectedOption) => handleSelectChange(selectedOption, fieldItem.fieldName)} required={fieldItem.required} isDisabled={readonly} />
                                                </div>
                                                {FieldErrors[fieldItem.fieldName] && (
                                                    <div className="invalid">
                                                        {FieldErrors[fieldItem.fieldName]}
                                                    </div>
                                                )}
                                            </div>
                                            }
                                            {
                                                fieldItem.fieldType === "dynamicMultiSelectdropdown" && <div key={i} className="form-group">
                                                    <label className="form-label">{fieldItem.label}:</label>
                                                    <Select isMulti options={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => (
                                                        { "value": res.id, "label": res[fieldItem.displayField] }
                                                    ))} placeholder={fieldItem.label}
                                                        value={dynamicOptions[fieldItem.fieldName] && dynamicOptions[fieldItem.fieldName].map((res) => ({ value: res.id, label: res[fieldItem.displayField], })).filter((option) => (Data[fieldItem.fieldName] || []).includes(option.value))} onChange={(selectedOption) => handleMultiSelectChange(selectedOption, selectedOption.value, fieldItem.fieldName)} required={fieldItem.required} isDisabled={readonly} />
                                                </div>
                                            }

                                            {/* {
                                            fieldItem.fieldType === "DynamicSelectOptions" && <div key={i} className="form-group">
                                                <label className="form-label">{fieldItem.label}:</label>
                                                <select className="form-select" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange} required={fieldItem.required}>
                                                    <option value="">SELECT {fieldItem.label}</option>
                                                    {
                                                       SelectDynamicOptions.map((optionItem, i) => {
                                                            return (
                                                                <option value={optionItem.id} key={i} selected={optionItem.id===Data[fieldItem.fieldName]?Data[fieldItem.fieldName]:undefined} >{optionItem[fieldItem.displayField]}</option>
                                                            )
                                                        })
                                                    }

                                                </select>
                                            </div>
                                        } */}
                                            {fieldItem.fieldType === "mulitipulFiles" && <div key={i} className="form-group">
                                                <label className="form-label">{fieldItem.label}</label>
                                                <input className="form-control" type="file" id="formFileMultiple" name={fieldItem.fieldName} multiple onChange={HandleMultiPulFiles} required={fieldItem.required} disabled={readonly} />
                                            </div>
                                            }
                                            {fieldItem.fieldType === "status" && <div key={i} className="form-group form-check form-switch">
                                                <div>
                                                    <label className="form-check-label">STATUS</label>
                                                </div>
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" name={fieldItem.fieldName} defaultValue={Data[fieldItem.fieldName]} onChange={HandleChange} />

                                            </div>
                                            }
                                            {fieldItem.fieldType === "label" && <div key={i} className="form-group">
                                                <h3 className="form-label">{fieldItem.label}</h3>
                                            </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="Loginerror">
                            <p>{error}</p>
                        </div>
                        {!readonly &&
                            <button type="submit" className={`btn btn-primary ${button ? button : ""}`} disabled={submitInProgress} >{submitInProgress ? "Loading ..." : <>{buttonTitle ? buttonTitle : "Submit"}</>}</button>
                        }
                    </form>
                )}
            </div>
        </>
    )
}
export { FormParser }