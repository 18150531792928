import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { BiSearch } from "react-icons/bi";
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import axiosConfig from "../Service/axiosConfig";
import { useNavigate } from 'react-router-dom';

const AuditionApplications = ({ Applications, handleClose, requirement }) => {
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState({ gender: '', role: '', search: '', application_status: "" });
    const [roles, setRoles] = useState([]);
    const [editingRowId, setEditingRowId] = useState(null);  // New state to track which row is being edited
    const [editFields, setEditFields] = useState({ application_status: '', message: '' });  // New state to track edited values
    const navigate = useNavigate();
    const fetchAuditionList = async () => {
        setLoading(true);
        console.log(filterData)
        try {
            const response = await axiosConfig.get(`accounts/audition-applications/`, {
                params: {
                    page: currentPage,
                    page_size: rowPerPage,
                    audition_requirement: requirement,
                    gender: filterData.gender,
                    role: filterData.role,
                    search: filterData.search,
                    application_status: filterData.application_status
                }
            });
            setTotalRows(response.data.count);
            setData(response.data.results);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    const fetchRoles = async () => {
        try {
            const response = await axiosConfig.get(`/masters/role-24-frames/`, { params: { page_size: 5 } });
            setRoles(response.data.results);
        } catch (error) {
            console.error(error);
        }
    };
    const handleNavigate = (link, type) => {
        if (type === "profile") {
            window.open(`/dashboard/users/${link}/edit`, '_blank');
        } else {
            navigate(`/auditions`);
        }
    };
    const handleEditClick = (row) => {
        setEditingRowId(row.id);
        setEditFields({ application_status: row.application_status, message: row.message });
    };
    const handleCancelClick = () => {
        setEditingRowId(null);
    };
    const handleFieldChange = (e, field) => {
        setEditFields(prevState => ({
            ...prevState,
            [field]: e.target.value
        }));
    };
    const handleSaveClick = async (row) => {
        try {
            await axiosConfig.put(`/accounts/audition-applications/${row.id}/`, {
                application_status: editFields.application_status,
                message: editFields.message,
                audition_requirement: requirement
            });
            setEditingRowId(null);  // Exit edit mode
            fetchAuditionList();  // Optionally refetch data
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };
    const genderOptions = [
        { value: '', label: '-Select-' },
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'both', label: 'Both' },
        { value: 'other', label: 'Other' }
    ];
    const handleSelectChange = (selectedOption, name) => {
        console.log(selectedOption, name)
        setFilterData(prevState => ({
            ...prevState,
            [name]: selectedOption?.value || ""
        }));
    };
    const handleSearchChange = (e) => {
        setFilterData(prevState => ({
            ...prevState,
            search: e.target.value
        }));
    };
    const calculateAge = dob => {
        const birthDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };
    const [orderStatuses, setOrderStatuses] = useState([
        { label: "--select--", value: "" },
        { label: "Applied", value: "applied" },
        { label: "Accepted", value: "accepted" },
        { label: "Shortlisted", value: "shortlisted" },
        { label: "On Hold", value: "onhold" },
        { label: "Cancelled", value: "cancelled" },
        { label: "Rejected", value: "rejected" },
    ]);
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * rowPerPage + i + 1,
            sortable: true,
            width: "80px"
        },
        {
            name: "Image",
            selector: row => (
                <div onClick={() => handleNavigate(row.profile?.id, "profile")}>
                    <img src={row.profile?.profile_pic} className="profile-pic" alt={row.profile.first_name} />
                </div>
            ),
            sortable: true,
            width: "100px"
        },
        {
            name: "Name",
            selector: "name",
            cell: row =>
                <>
                    {row.profile && `${row.profile.hcc_id} - ${row.profile.first_name} (${row.profile.gender}) - ${calculateAge(row.profile?.dob)} yrs - ${row.profile?.height ? row.profile?.height : ""}`}
                </>,
            sortable: true
        },
        {
            name: "Mobile No.",
            selector: "mobile_number",
            cell: row => row.profile?.mobile_number,
            sortable: true
        },
        {
            name: "Role",
            selector: "designation",
            cell: row => row.profile?.designation || "----",
            sortable: true
        },
        {
            name: "Location",
            selector: "location",
            cell: row => <div>{row.profile?.present_state}-{row.profile?.present_city}</div>,
            sortable: true
        },
        {
            name: "Status",
            selector: "application_status",
            cell: row => (
                editingRowId === row.id ? (
                    <select
                        value={editFields.application_status}
                        onChange={(e) => handleFieldChange(e, 'application_status')}
                    >
                        {orderStatuses.map(status => (
                            <option key={status.value} value={status.value}>
                                {status.label}
                            </option>
                        ))}
                    </select>
                ) : (
                    row.application_status
                )
            ),
            sortable: true
        },
        {
            name: "Feedback",
            selector: "message",
            cell: row => (
                editingRowId === row.id ? (
                    <textarea value={editFields.message} onChange={(e) => handleFieldChange(e, 'message')} />
                ) : (
                    row.message
                )
            ),
            sortable: true
        },
        {
            name: "Actions",
            selector: row => {
                return (
                    <div className="row">
                        {editingRowId === row.id ? (
                            <>
                                <div className='Audi-require' onClick={() => handleSaveClick(row)}>Submit</div>
                                <div className='Audi-require' onClick={handleCancelClick}>Cancel</div>
                            </>
                        ) : (
                            <>
                                {/* <div className='Audi-require' onClick={() => handleEditClick(row)}>Edit</div> */}
                                <div className='Audi-require' onClick={() => handleNavigate(row.profile?.id, "profile")}>View profile</div>
                            </>
                        )}
                    </div>
                );
            }
        }
    ];
    useEffect(() => {
        if (Applications) {
            fetchAuditionList();
        }
    }, [Applications, currentPage, rowPerPage, filterData]);
    useEffect(() => {
        fetchRoles();
    }, []);

    return (
        <Modal
            size="lg"
            show={Applications}
            onHide={handleClose}
            aria-labelledby="example-modal-sizes-title-lg"
            className="modal-size-craft application-lists"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>List Of Applicants</Modal.Title>
            </Modal.Header>
            <Modal.Body className="success-icon">
                <div>
                    <b>Received Applications</b> ({totalRows})
                </div>
                <div>
                    <div className="application-filters">
                        <Select
                            className="flex-1 w-25"
                            options={orderStatuses}
                            name="application_status"
                            onChange={selectedOption => handleSelectChange(selectedOption, "application_status")}
                            placeholder="Application Status"
                        />
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationTotalRows={totalRows}
                    paginationPerPage={rowPerPage}
                    paginationDefaultPage={currentPage}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                    fixedHeader
                    fixedHeaderScrollHeight={`calc(100vh - 200px)`}
                    paginationPerPageOptions={[10, 20, 50, 100, 200]}
                    paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
                    paginationServer subHeader
                    customStyles={{
                        rows: { style: {} },
                        cells: { style: { justifyContent: "center" } },
                        headCells: {
                            style: {
                                color: "#212529",
                                fontSize: "12px",
                                fontWeight: 500,
                                lineHeight: "28px",
                                justifyContent: "center"
                            }
                        }
                    }}
                    progressPending={loading}
                    progressComponent={<div className="loader-container">
                        <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." />
                    </div>}
                />
            </Modal.Body>
        </Modal>
    );
};
export { AuditionApplications };