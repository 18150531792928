import React, { useEffect, useState } from 'react'
import { FormParser, PopupModal } from "../Components";
import Masters from '../Components/masters.json'
import axiosConfig from "../Service/axiosConfig";
import { useParams } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import Select from 'react-select';
const ProfileVerification = () => {
    let { id } = useParams();
    const [Data, SetData] = useState({});
    const [readOnly, setReadOnly] = useState(true);
    const [Message, setMessage] = useState("")
    const [popupType, setpopupType] = useState("");
    const [loading, setLoading] = useState(false)
    const [key, setKey] = useState("0")
    const [VerifyId, setVerifyId] = useState(null)
    const [tabledata, settabledata] = useState([])
    const [roles, setRoles] = useState([])
    const [desigdata, setdesigdata] = useState({})
    const getRoles = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/role-24-frames/?page_size=1000`)
            console.log(responce)
            setRoles(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }

    const formSubmit = (formData) => {
        console.log(Data, "data", formData)
        delete formData.govt_id_proof_front_copy;
        delete formData.govt_id_proof_back_copy;
        axiosConfig.patch(`/accounts/verified-profile/${Data.id}/`, formData).then(res => {
            console.log(res)
            GetVerifiedData()
            setMessage("Verified Updated SuccessFully!")
            setpopupType("success");
        }).catch(error => {
            console.log(error.response)
        })
    };
    const GetVerifiedData = () => {
        setLoading(true)
        axiosConfig.get(`/accounts/verified-profile/?profile=${id}`).then(res => {
            console.log(res.data.results)
            if (res.data.results.length > 0) {
                SetData({
                    ...res.data.results[0],
                    "present_state": res.data.results[0].present_state?.id,
                    "present_city": res.data.results[0].present_city?.id,
                    "permanent_state": res.data.results[0].permanent_state?.id,
                    "permanent_city": res.data.results[0].permanent_city?.id,
                })
            }
            setVerifyId(res.data.results[0].id)
            setLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }
    const GetData = async () => {
        try {
            const response = await axiosConfig.get(`/accounts/verified-profile-designations/?verified_profile=${VerifyId}`);
            console.log(response);
            settabledata(response.data.results)
        } catch (error) {
            console.log(error);
        }
    }
    const HandleChange = (e) => {
        e.preventDefault();
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        setdesigdata({ ...desigdata, [name]: value })
    }
    const handleSelectChange = (selectedOption, name) => {
        setdesigdata({ ...desigdata, [name]: selectedOption?.value ? selectedOption?.value : "" });
    }
    const handleFileChange = (e) => {
        e.preventDefault();
        const name = e.target.name
        const file = e.target.files[0];
        console.log(file)
        setdesigdata({ ...desigdata, [name]: file })
    };
    const formSubmit1 = (FormData) => {
        console.log("desigdata", desigdata)
        const data_to_post = {
            ...desigdata,
            verified_profile: Data.id
        }
        if (desigdata.id) {
            axiosConfig.patch(`/accounts/verified-profile-designations/${desigdata.id}/`, desigdata)
                .then(res => {
                    console.log(res)
                    GetData()
                }).catch(error => {
                    console.log(error.response)
                })
        } else {
            axiosConfig.post("/accounts/verified-profile-designations/", data_to_post, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(res => {
                    console.log(res)
                    GetData()
                    setdesigdata({})
                }).catch(error => {
                    console.log(error.response)
                    setpopupType("error")
                })
        }
    }
    useEffect(() => {
        GetVerifiedData()
        GetData()
        getRoles()
    }, [])
    return (
        <>
            <Accordion activeKey={key} onSelect={(k) => setKey(k)}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Basic Details</Accordion.Header>
                    <Accordion.Body>
                        <FormParser modelObject={Masters.VerificationProfile} formData={Data} formSubmit={formSubmit} error={Error} />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Professional Details</Accordion.Header>
                    <Accordion.Body>
                        <div className="customtable-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Sr</th>
                                        <th>Designation Name</th>
                                        <th>designation union name</th>
                                        <th>designation union Proof</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tabledata.map((frame, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{frame.role?.frame_name || '1'}</td>
                                                <td>{frame.designation_union_name || ' '}</td>
                                                <td>
                                                    {frame.designation_union_copy ? (
                                                        <img src={frame.designation_union_copy} alt="Designation Union Copy" style={{ width: '100px', height: 'auto' }} />
                                                    ) : (
                                                        "No Image"
                                                    )}
                                                </td>
                                                <td>
                                                    <div>
                                                        {/* <MdOutlineEdit onClick={() => getdesignationdata(frame.id)} /> */}
                                                        {/* <MdOutlineDelete onClick={() => getdesignationdata(frame.id)} /> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td>{tabledata.length + 1}</td>
                                        <td>
                                            <Select
                                                options={roles.map((res) => ({ "value": res.id, "label": res.frame_name }))}
                                                placeholder="Select Designation"
                                                value={roles.map(res => ({ "value": res.id, "label": res.frame_name })).find(res => res.value === Data.role)}
                                                onChange={(selectedOption) => handleSelectChange(selectedOption, "role")}
                                                required
                                            />
                                        </td>
                                        <td>
                                            <input type="text" className="form-control" id="validationCustom01" placeholder="Designation Union Name" name="designation_union_name" onChange={HandleChange} required />
                                        </td>
                                        <td>
                                            <input type="file" className="form-control" placeholder="Designation Union Proof" name="designation_union_copy" onChange={handleFileChange} required />
                                        </td>
                                        <td onClick={() => formSubmit1()} style={{ color: "#ef443b", textDecoration: "underline" }}>+ Add</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Status</Accordion.Header>
                    <Accordion.Body>
                        <FormParser modelObject={Masters.Verified} formData={Data} formSubmit={formSubmit} error={Error} readOnly={readOnly} setReadOnly={setReadOnly} />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            {popupType != "" &&
                <PopupModal popupType={popupType} Navigation="" setpopupType={setpopupType} Message={Message} />
            }
        </>
    )
}

export { ProfileVerification }
