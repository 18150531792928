import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig"
import { Footer, Navbar, DeletePopupModal, FormParser } from "../Components";
import { useNavigate, useSearchParams, NavLink, useParams } from "react-router-dom";
import { MdOutlineDelete, MdOutlineEdit, MdOutlineMoreVert, MdOutlineTouchApp } from "react-icons/md";
import { Card, Row, Col, Modal } from 'react-bootstrap';
import Masters from "../Components/masters.json"
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { AuditionApplications } from "./AuditionApplications";

const AuditionRequirements = () => {
    let { id } = useParams();
    console.log(id)
    const [requirementShow, setRequirementShow] = useState(false)
    const [requirementData, setRequirementData] = useState([])
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [Data, setData] = useState({})
    const [Applications, setapplications] = useState(false)
    const [RequireId, setRequireId] = useState(null)
    const GetRequirementdata = async () => {
        try {
            const responce = await axiosConfig.get(`/accounts/audition-requirements/?audition=${id}`)
            console.log(responce)
            setRequirementData(responce.data.results);
        }
        catch (error) {
            console.log(error)
        }
    }
    const AudiRequirements = (data) => {
        console.log(Data)
        const orderTopost = {
            ...data,
            audition: id
        }
        console.log(orderTopost)
        if (Data.id) {
            axiosConfig.patch(`/accounts/audition-requirements/${Data.id}/`, orderTopost).then(res => {
                console.log(res)
                GetRequirementdata()
                setRequirementShow(false)
            }).catch(error => {
                console.log(error.response.data)
                // setpopupType("error");
            })
        } else {
            axiosConfig.post("/accounts/audition-requirements/", orderTopost).then(res => {
                console.log(res)
                GetRequirementdata()
                setRequirementShow(false)
            }).catch(error => {
                console.log(error.response.data)
                // setpopupType("error");
            })
        }
    }
    const HandleEdit = (id) => {
        axiosConfig.get(`/accounts/audition-requirements/${id}/`).then(res => {
            console.log(res.data)
            setData({
                ...res.data,
            })
            setRequirementShow(true)
        }).catch(error => {
            console.log(error)
        })
    }
    const DeleteAlert = (id) => {
        SetRowId(id)
        SetAlertDeletePopupType("success")
    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    const HandleDelete = async (id) => {
        await axiosConfig.delete(`/accounts/audition-requirements/${id}/`).then(() => {
            SetAlertDeletePopupType("error")
            setDeletepopupType("success")
            GetRequirementdata();
        }).catch((error) => {
            setDeletepopupType("error")
            SetAlertDeletePopupType("error")
            console.log(error)
        })
    }
    const handlShow = (id) => {
        setapplications(true)
        setRequireId(id)
    }
    const handleClose = () => {
        setapplications(false)
        setRequireId(null)
    }
    useEffect(() => {
        GetRequirementdata()
    }, [])
    return (
        <>
            <div className="row">
                <div className="col-9">
                    <h6>Requirements</h6>
                </div>
                <div className="col-3">
                    {/* <button type="button" className="btn btn-success apply-new-craft" onClick={() => setshowApply(true)} >Apply New Craft</button> */}
                    <button className="btn btn-success apply-new-craft" onClick={() => {
                        setData({})
                        setRequirementShow(true)
                    }}>+Add Requirements</button>
                </div>
            </div>
            {requirementShow &&
                <Modal
                    size="lg"
                    show={requirementShow}
                    onHide={() => {
                        setData({})
                        setRequirementShow(false)
                    }}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Add Audition Requirements
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormParser modelObject={Masters.AditionRequirements} formData={Data} formSubmit={AudiRequirements} error={Error} />
                    </Modal.Body>
                </Modal>
            }
            <div className="investment-screen">
                <Row>
                    {requirementData.map((requirement, index) => (
                        <Col xl={4} lg={4} md={6} sm={12} xs={12} key={index}>
                            <Card className="bax-shadow" style={{ position: "relative" }}>
                                <Card.Body>
                                    <Card.Title className="card-title-custom">Role - {requirement.character}</Card.Title>
                                    <Card.Text>
                                        <div className="applictn-dtls">
                                            {requirement.gender && <div className="apply-content">
                                                Gender : <span className="apply-data"> {requirement.gender}</span >
                                            </div>}
                                            {requirement.from_age && <div className="apply-content">
                                                Age : <span className="apply-data"> {requirement.from_age} - {requirement.to_age} years</span>
                                            </div>}
                                            {requirement.budget && <div className="apply-content">
                                                Budget : <span className="apply-data"> {requirement.budget ? requirement.budget : 0}</span>
                                            </div>}
                                            {requirement.no_of_requirements && <div className="apply-content">
                                                No. of Positions : <span className="apply-data"> {requirement.no_of_requirements}</span>
                                            </div>}
                                            {requirement?.loc_lang?.languages && <div className="apply-content">
                                                Preffered Languages: <span className="apply-data"> {requirement?.loc_lang?.languages}</span>
                                            </div>}
                                            {requirement?.loc_lang?.state && <div className="apply-content">
                                                Preffered Location: <span className="apply-data"> {requirement?.loc_lang?.state}</span>
                                            </div>}
                                            {requirement.specification && <div className="apply-content">
                                                Specification: <span className="apply-data"> {requirement.specification}</span>
                                            </div>}
                                        </div>
                                    </Card.Text>
                                    <Card.Footer className="text-center" style={{ background: "none", borderTop: "none", paddingLeft: '0px' }}>
                                        <button className="btn btn-info w-50" onClick={() => HandleEdit(requirement.id)}>Edit</button>
                                        <button className="btn btn-secondary w-50" onClick={() => DeleteAlert(requirement.id)}>Delete</button>
                                        <button type="button" className="btn btn-primary w-100" onClick={() => handlShow(requirement.id)}>View Applications({requirement.applications})</button>
                                    </Card.Footer>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
            {
                AlertDeletePopupType === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
            }
            {Applications &&
                <AuditionApplications Applications={Applications} setapplications={setapplications} handleClose={handleClose} requirement={RequireId} />
            }
        </>
    )
}
export { AuditionRequirements };