import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal, FormParser } from "../Components";
import { NavLink, useNavigate } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig"
import { MdDeleteOutline, MdOutlineEdit, MdOutlineRemoveRedEye } from "react-icons/md"
import DataTable from 'react-data-table-component';
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import "./master_categories.css";
import { MarketingNavbarComponent } from "../Components/navbar/marketingnav";
const AudiInterests = () => {
    const [Data, SetData] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [loading, setLoading] = useState(false)
    const [selectedImage, setSelectedImage] = useState({ image: "", id: 0 });
    const [RowId, SetRowId] = useState()
    const [search, SetSearch] = useState({
        search: ""
    })
    let navigate = useNavigate();
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        axiosConfig.get(`/accounts/verified-profile/?search=${value}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const GetData = async () => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`/accounts/audition-interests/?page=${currentPage}&page_size=${RowPerPage}`);
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage]);
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Profile",
            selector: "character",
            cell: row => row?.profile ? row?.profile?.hcc_id : "--",
            sortable: true
        },
        {
            name: "Gender",
            selector: "character",
            cell: row => row?.profile ? row?.profile?.gender : "--",
            sortable: true
        },
        {
            name: "Mobile No.",
            selector: "character",
            cell: row => row?.profile ? row?.profile?.mobile_number : "--",
            sortable: true
        },
        {
            name: "Pro Membership",
            selector: "is_pro",
            cell: row => `${row.profile?.is_pro === true ? "YES" : "NO"}`,
            sortable: true
        },
        {
            name: "Audition Requirement",
            selector: "character",
            cell: row => row?.audition_requirement ? row?.audition_requirement?.id : "--",
            sortable: true
        },
        // {
        //     name: "ACTIONS",
        //     selector: row => {
        //         const DeleteAlert = () => {
        //             SetRowId(row.id)
        //             SetAlertDeletePopupType("success")
        //         }
        //         const CancelAlerrt = () => {
        //             SetAlertDeletePopupType("error")
        //         }
        //         const HandleDelete = async (id) => {
        //             await axiosConfig.delete(`/accounts/verified-profile/${id}/`).then(() => {
        //                 SetAlertDeletePopupType("error")
        //                 setDeletepopupType("success")
        //                 GetData();
        //             }).catch((error) => {
        //                 setDeletepopupType("error")
        //                 SetAlertDeletePopupType("error")
        //                 console.log(error)
        //             })
        //         }
        //         return (
        //             <>
        //                 <div className="row" >
        //                     <div className="col-4">
        //                         <MdOutlineRemoveRedEye className="dashboard-tabe-data-actions" onClick={() => handleRowClick(row.id)} />
        //                     </div>
        //                     <div className="col-4">
        //                         <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
        //                     </div>
        //                 </div>

        //                 {
        //                     AlertDeletePopupType === "success" &&
        //                     <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
        //                 }
        //             </>
        //         )

        //     }
        // }
    ]
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <MarketingNavbarComponent />
                    <div className="childnav-content">
                        <div className="table-responsive marginTop">
                            <DataTable
                                columns={columns}
                                data={Data} pagination paginationTotalRows={totalRows}
                                paginationPerPage={RowPerPage}
                                paginationDefaultPage={currentPage}
                                onChangePage={(page) => setCurrentPage(page)}
                                paginationPerPageOptions={[10, 20, 30, 50, 100, totalRows]}
                                paginationRowsPerPageOptions={[10, 20, 30, 50, 100, totalRows]}
                                onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                                paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                                progressComponent={<div className="loader-container">
                                    <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                                </div>}
                            />
                        </div>
                    </div>
                </div>
                {/* {popupType != "" &&
                    <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="/dashboard/masters/products" />
                }
                {deletePopupType != "" &&
                    <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                } */}
            </DashboardLayout >
        </>
    )
}
export { AudiInterests }